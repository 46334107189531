<template>
<!--说明
  lottery_status
            -1  抽奖还没开始,活动没结束
             0  有抽奖资格，还没滚动，
             1  有抽奖资格以滚动，已经抽奖
             2  获得抽奖机会
             3  没有抽奖资格 -->
      <div class="wrapper">
        <div v-if="lotterystatus == 0 || lotterystatus == 1 || lotterystatus == 2" class="sign-flag ok" :style="sign_bg_img_ok">
            <!-- 0  有抽奖资格，还没滚动， -->
            <router-link  v-if="lotterystatus==0" class="tiemst item"  tag="div" to="/personal/sign-in21day/lottery">去抽奖</router-link>
            <!-- 1  有抽奖资格以滚动，已经抽奖 -->
            <div v-if="lottery && lotterystatus===1" class="tiemst item" >
              <span v-if="lottery.lottery_remark === '很抱歉,您没有抽到任何奖品'">【{{lottery.lottery_remark}}】</span>
              <span v-else>【您获得{{lottery.lottery_remark}}一个】</span>
            </div>
            <!-- 2  获得抽奖机会 -->
            <div v-if="lotterystatus===2" class="tiemst item" ><span >【{{lottery.lottery_remark}}】</span></div>
            <!-- 打卡天数 -->
            <div :class="{signflaginfo2: lotterystatus===0 || lotterystatus===1  || lotterystatus===2,'sign-flag-info':lotterystatus===3}" >
              您共计打卡{{ signList.length }}天，超越了{{ proportion }}%的读者。
              </div>
       </div>
       <div v-if="lotterystatus==3" class="sign-flag no" :style="sign_bg_img_no">
            <!-- 打卡天数 -->
            <div :class="{signflaginfo2: lotterystatus===0 || lotterystatus===1  || lotterystatus===2,'sign-flag-info':lotterystatus===3}" >
              您共计打卡{{ signList.length }}天，超越了{{ proportion }}%的读者。
              </div>
       </div>
       <!-- 初始化页面  -1  抽奖还没开始,活动没结束-->
      <div v-if="lotterystatus==-1" class="sign-init" :style="sign_init_st"></div>

    <div class="sign-body">
      <div class="sign-block">
        <div class="title">{{ lotmonth }}</div>
        <div v-if="dateList.length>0" class="sign">
          <div class="item week">周一</div>
          <div class="item week">周二</div>
          <div class="item week">周三</div>
          <div class="item week">周四</div>
          <div class="item week">周五</div>
          <div class="item week">周六</div>
          <div class="item week">周日</div>
          <div class="item" v-for="index of Number(dateList[0].date_week < 0?1:dateList[0].date_week) -1" :key="index"></div>
          <div
              class="item date"
              v-for="(item,index) in dateList"
              :key="index"
              @click="sign(item)"
          >
            <div
                class="round"
                :class="{reissue:item.is_before && !item.is_sign,'sing-in':item.is_sign}"
            ></div>
            <div class="text">{{ item.date }}</div>
          </div>
        </div>
      </div>
    </div>
     <div class="introduceconst">
      打卡明细
    </div>
     <div class="introduce">
      <div class="introduce-block">
        <div
            class="log-item"
            v-for="(item,index) in signList"
            :key="index"
        >
          <div class="content">{{ item.sign_in_remark }}</div>
          <div class="time">{{ item.create_time }}</div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { getCurrentConnect } from '@/api/Activity'
import { mapGetters } from 'vuex'
export default {
  name: 'Sign',
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid'
    ])
  },
  props: {
    dateList: {
      type: Array,
      default () {
        return []
      }
    },
    signList: {
      type: Array,
      default () {
        return []
      }
    },
    spread: {
      type: String,
      default: '0'
    },
    proportion: {
      type: String,
      default: '0'
    },
    lottery: { // 中奖结果
      type: Object,
      default () {
        return {}
      }
    },
    lotterystatus: { // 活动状态
      type: Number,
      default: -1
    }

  },
  data () {
    return {
      dayts: [],
      activity: {},
      sign_init_st: '',
      sign_bg_img: '',
      sign_bg_img_no: '',
      sign_bg_img_ok: '',
      lotmonth: '',
      c_user_guid: ''
    }
  },
  created () {
    this._getCurrentConnect()
  },
  mounted () {

  },
  methods: {
    sign (item) {
      this.$emit('sign', item)
    },
    _getCurrentConnect () {
      let _this = this
      console.log({ activity_type_new: 1, c_user_guid: this.userGuid })
      getCurrentConnect({ activity_type_new: 1, c_user_guid: this.userGuid, agency_guid: this.agencyGuid }).then(res => {
        if (res.code === 200) {
          _this.activity = res.data
          if (res.data.activitySetting) {
            if (res.data.activitySetting.activity_home_bg_img) {
              _this.sign_init_st = 'background-image:url(' + res.data.activitySetting.activity_home_bg_img + ')'
            }
            if (res.data.activitySetting.lose_qualification_bg_img) {
              _this.sign_bg_img_no = 'background-image:url(' + res.data.activitySetting.lose_qualification_bg_img + ')'
            }
            if (res.data.activitySetting.get_qualification_bg_img) {
              _this.sign_bg_img_ok = 'background-image:url(' + res.data.activitySetting.get_qualification_bg_img + ')'
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%

  .sign-adv
    position relative
    z-index -1
    display flex
    width 100%
    height 532px
    pointer-events none

  .sign-flag
    display flex
    justify-content center
    flex-wrap wrap
    width 100%
    height 400px
    background-size 100% 100%
    background-repeat no-repeat

    &.ok
      bg-image2('assets/lottery/sgin-assess')
      .tiemst{
          position: absolute;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FDCE96;
          top 200px
          left: 25%;
          bg-image2('assets/lottery/go-to-lottery')
          background-size 100% 100%
          background-repeat no-repeat
          &.item{
            display flex
            align-items center
            justify-content center
            width 380px
            min-height 40px
            line-height normal
            text-align: center;
            }

        }
      .signflaginfo
        text-align center
        margin-top 210px
        color rgba(255, 255, 255, 1)
      .signflaginfo2
        position: absolute;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FDFCFC;
        text-align center
        top 270px
        left: 25%;
        color rgba(255, 255, 255, 1)
      .go-lottery
        display flex
        align-items center
        justify-content center
        width 140px
        height 40px
        line-height normal
        margin-top 214px
        font-size 20px
        color rgba(253, 206, 150, 1)
        bg-image2('assets/lottery/go-to-lottery')
        background-size 100% 100%
        background-repeat no-repeat

      .lottery_remark
        display flex
        align-items center
        justify-content center
        width 380px
        height 40px
        line-height normal
        margin-top 214px
        font-size 20px
        color rgba(253, 206, 150, 1)
        bg-image2('assets/lottery/go-to-lottery')
        background-size 100% 100%
        background-repeat no-repeat

      .lottery_toast
        display flex
        justify-content center
        align-items center
        width 100%
        font-size 18px
        color rgba(157, 26, 5, 1)
        margin-top -180px

    &.no
      background-image: url("https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/230913/5916b69a-cbf4-f45c-e37a-d6a569008f42.png")

      .sign-flag-info
        margin-top 200px
        margin-left 230px;
        //color rgba(116, 107, 81, 1)
        color: #FFFFFF;

    .sign-flag-info
      display flex
      align-items center
      height 50px
      line-height normal
      font-size 24px

  .sign-body
    position relative
    width 750px
    margin-top -15px
    padding 20px 10px
    box-sizing border-box
    border-radius 20px 20px 0 0;
    background rgba(77, 180, 90, 1)

    &::after
      content ''
      position absolute
      left 0
      top -34px
      width 230px
      height 104px
      //bg-image2('uploads/sgin-in/label')
      background-size 100% 100%
      background-repeat no-repeat

    .sign-block
      display flex
      flex-wrap wrap
      justify-content center
      align-items center
      width 100%
      padding-bottom 20px
      box-sizing border-box
      border-radius 28px
      background rgba(77, 180, 90, 1)
      border 0px solid rgba(247, 195, 171, 1)

      .title
        display flex
        align-items center
        justify-content center
        width 750px
        height 80px
        line-height normal
        text-align center
        font-size 26px
        color rgba(255, 255, 255, 1)
        font-weight bold

      .sign
        display flex
        flex-wrap wrap
        width 690px

        .item
          position relative
          display flex
          justify-content center
          flex-wrap wrap
          width 98px

          &.week
            height 30px
            margin-bottom 20px
            line-height normal
            font-size 22px
            color rgba(255, 255, 255, .5)

          &.date
            height 90px

          .round
            position absolute
            z-index 1
            width 46px
            height 46px
            border-radius 23px
            background rgba(255, 255, 255, 1)

            &.reissue
              bg-image2('uploads/sgin-in/sgin_bk')
              background-size 100% 100%
              background-repeat no-repeat

            &.sing-in
              bg-image2('uploads/sgin-in/sgin_yq')
              background-size 100% 100%
              background-repeat no-repeat

          .text
            position absolute
            bottom 10px
            display flex
            justify-content center
            align-items center
            width 100%
            line-height normal
            font-size 18px
            color rgba(255, 255, 255, .5)

          .line
            position absolute
            z-index 0
            top 22px
            width 100%
            height 2px
            background rgba(255, 218, 170, 1)

  .introduceconst{
      position: relative;
      background rgba(245, 245, 244, 1)
      height 90px
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      display:flex;
      justify-content: center;
      align-items: center;
      border-radius 20px 20px 0 0;
      margin-top: -13px;

    }

  .introduce
    width 100%
    .introduce-block
      width 100%
      padding 20px
      margin-top: -5px;
      position: relative;
      border-radius 28px  28px 0 0;
      box-sizing border-box
      background rgba(255, 255, 255, 1)
      font-size 26px
      color rgba(255, 255, 255, 1)
      line-height 50px

      .title-pic
        width 460px
        height 100px
        margin 0 auto
        bg-image2('uploads/sgin-in/title-pic')
        background-size 100% 100%
        background-repeat no-repeat

      .log-title
        width 472px
        height 100px
        margin 0 auto
        bg-image2('uploads/sgin-in/log-title')
        background-size 100% 100%
        background-repeat no-repeat

      .log-item
        display table
        width 690px
        height 60px
        padding-left 20px
        margin-top 10px
        display:flex;
        .content
          width 100%
          height 26px
          line-height 30px
          margin-top 20px
          font-size 26px
          color rgba(51, 51, 51, 1)

        .time

          height 22px
          line-height 26px
          margin-top 18px
          font-size 22px
          color rgba(153, 153, 153, 1)

   .dayMain{
     width:100%;
     height 90px
     display:flex;
     align-items: center;
     justify-content: space-around;
     .item{
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        display:flex;
        align-items: center;
        .day1{
            width: 28px;
            height: 48px;
            background: #F37E22;
            border-radius: 4px;
            font-size: 40px;
            font-family: LcdD;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 5px;
            margin-left: 5px;
            text-align: center;
          }
      }
      .lien{
         color #999999;
        }
    }
  .sign-init{
    width 100%
    height 400px
    bg-image2('uploads/sgin-in/sgin_init2023')
    background-size 100% 100%
    background-repeat no-repeat
  }
</style>
